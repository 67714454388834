<template>
    <AuthWrapper>
        <AuthTop
            heading="Välkommen"
            text="Fyll i dina uppgifter för att logga in"
        />

        <form
            class="mb-6"
            @submit.prevent="login"
        >
            <AuthField
                v-model="email"
                label="E-postadress"
                name="email"
                type="email"
                autocomplete="username"
            />

            <AuthField
                v-model="password"
                label="Lösenord"
                name="password"
                type="password"
                class="mb-8"
                autocomplete="current-password"
            />

            <AuthButton
                :loading="loading"
                label="Logga in"
            />
        </form>

        <AuthLink
            class="forgotPasswordText"
            title="Glömt ditt lösenord?"
            url="/forgot-password"
        />
    </AuthWrapper>
</template>

<script>
import AuthWrapper from '@/components/auth/AuthWrapper';
import AuthTop from '@/components/auth/AuthTop';
import AuthField from '@/components/auth/AuthField';
import AuthLink from '@/components/auth/AuthLink';
import AuthButton from '@/components/auth/AuthButton';
import { mapGetters } from 'vuex';

export default {
    components: {
        AuthWrapper,
        AuthTop,
        AuthField,
        AuthButton,
        AuthLink
    },

    data: () => ({
        email: null,
        password: null
    }),

    computed: {
        ...mapGetters('auth', ['authUser', 'error', 'loading', 'isAdmin'])
    },

    methods: {
        async login () {
            await this.$store.dispatch('auth/login', this.$data);

            if (this.authUser && this.isAdmin) {
                this.$router.push('/admin');
            } else if (this.authUser) {
                this.$router.push(this.$route.query.redirect || '/');
            }
        }
    }
};
</script>
