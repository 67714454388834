<template>
    <div class="authLink text-center">
        <router-link
            :to="url"
            class="font-semibold tracking-wide text-xl"
        >
            {{ title }}
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        url: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.authLink {
    color: var(--secondaryColor);

    &:hover {
        color: var(--primaryColor);
    }
}
</style>
