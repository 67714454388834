<template>
    <Button
        :loading="loading"
        :label="label"
        class="authButton"
        type="submit"
    />
</template>

<script>
import Button from '@/components/ui/Button';

export default {
    components: {
        Button
    },

    props: {
        label: {
            type: String,
            required: true
        },

        loading: {
            type: Boolean,
            default: false
        }

    }
};
</script>

<style lang="postcss" scoped>
.authButton.button {
    background-color: var(--secondaryColor);

    &.isLoading,
    &:hover {
        background-color: var(--primaryColor);
    }

    &.isLoading {
        color: var(--primaryColor);
    }
}
</style>
